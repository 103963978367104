/* Homepage styling */

/* Projects list */
.projectsList {
    gap: 14rem;
    padding-bottom: 0.75rem;
    padding-top: 0rem;
}
/* Large screens (laptops) */
@media (max-width: 1440px) {
    .projectsList {
        gap: 12rem;
        /* padding-bottom: 2rem; */
    }
}
/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .projectsList {
        gap: 10rem;
        padding-bottom: 2rem;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .projectsList {
        gap: 8rem;
        padding-bottom: 2rem;
    }
}

.projectsListItem {
    /* cursor: pointer; */
    transition: transform 0.05s ease-in-out;
}

.projectsListItem:hover {
    /* transform: translateY(-12px) !important; */
    transform: scale(1.025) !important;
    opacity: 86% !important;
}
/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .projectsListItem {
        flex-direction: column;
    }
}

.cursorHand, .cursorHand:hover, .cursorHand > svg, .cursorHand > svg > path {
    cursor: url('../assets/cursor-hand.svg'), auto !important;
}

.cursorEyes {
    cursor: url('../assets/cursor-eyes.svg'), auto !important;
}

.projectsListImage {
    padding: 2.75rem;
}

.projectsListItemRight {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.videoProjectsList {
    width: 720px;
    max-height: 100%;
    border-radius: 1rem;
}
/* Large screens (laptops) */
@media (max-width: 1440px) {
    .videoProjectsList {
        width: 100%;
        border-radius: 1rem;
    }

    .videoProjectsListMount {
        padding: 7%;
    }

    .projectsListItemRight {
        min-width: 300px;
    }
}
/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .videoProjectsListMount {
        width: 100%;
        padding: 2.5rem;
    }

    .projectsListItemLeft {
        width: 90%;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .videoProjectsList {
        width: 100%;
        border-radius: 0.5rem;
    }

    .videoProjectsListMount {
        padding: 1.25rem;
    }

    .projectsListItemLeft {
        width: 100%;
    }

    .projectsListItemRight {
        width: 100%;
        padding-top: 1rem;
    }
}

.hoverContainer {
    position: relative;
    display: inline-block;
    /* cursor: pointer; */
    cursor: url('../assets/cursor-hand.svg'), auto;
}

.popupImage {
    position: absolute;
    display: none;
    width: 250px;
    height: auto;
}

.collab {
    top: -46px;
    left: 36px;
}

.name {
    top: 10px;
    left: 180px;
}

.eating {
    top: -172px;
    left: 250px;
}

.creating {
    top: -50px;
    left: -404px;
}

.adventures {
    top: -106px;
    left: 540px;
}

.experimenting {
    top: 116px;
    left: 365px;
}

.laughing {
    top: -150px;
    left: -390px;
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .eating {
        top: -150px;
        left: 440px;
    }
    
    .creating {
        top: -50px;
        left: -560px;
    }
    
    .adventures {
        top: -100px;
        left: 540px;
    }
    
    .experimenting {
        top: 116px;
        left: 545px;
    }
    
    .laughing {
        top: -150px;
        left: -550px;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .eating {
        display: none;
    }
    
    .creating {
        display: none;
    }
    
    .adventures {
        display: none;
    }
    
    .experimenting {
        display: none;
    }
    
    .laughing {
        display: none;
    }
}

.hoverContainer:hover .popupImage {
    display: block;
}
  
.hoverContainerTag:hover {
    opacity: 80%;
}

.tagsContainer {
    /* position: absolute; */
    bottom: 2.25rem;
    right: -1.5rem;
    display: flex;
    flex-direction: column; /* Stack tags vertically */
    gap: 5px; /* Adjust spacing between tags */
    z-index: 1; 
    height: 180px;
    /* height:400px; */
    width: 1000px;
    margin-right: 2rem;
    /* background: linear-gradient(335deg, rgb(156, 156, 156, 0.08) 20%, rgba(156, 156, 156, 0) 49.4%); */
}

.arrowHero {
    text-align: left;
    position: absolute;
    left: -50px;
    bottom: 126px;
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .arrowHero {
        display: none;
    }
}

/* Medium screens (tablets) */
@media (max-width: 1150px) {
    .tagsContainer {
        background: none;
        width: 100%;
        bottom: 0rem;
        right: 0rem;
    }
}

.logoHoverContainer {
    position: relative;
}

.logoHoverContent {
    /* visibility: hidden;
    opacity: 0;
    transform: scale(0.5); */
    transform: scale(1.2);
    transition: visibility 0s, opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
    color: var(--PRIMARY-BLUE-500);
}

.logoHoverContainer:hover .logoHoverContent {
    /* visibility: visible;
    opacity: 1;
    transform: scale(1.36); */
    transform: scale(1.4) rotate(90deg); /* Rotates the element by 45 degrees */

}

.footerEmojis {
    transform: scale(1.2);
    transition: visibility 0s, opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
    color: var(--BLACK);
    margin-bottom: 0rem;
    padding-top: 0.2rem;
}
.footerEmojis:hover {
    transform: scale(1.4) rotate(90deg);
}
.recentWorkEmojis1 {
    transition: visibility 0s, opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
}
.recentWorkEmojis1:hover {
    transform: scale(1.35) rotate(-10deg);
}
.recentWorkEmojis2 {
    transition: visibility 0s, opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
}
.recentWorkEmojis2:hover {
    transform: scale(1.35) rotate(10deg);
}

.aboutTopRight {
    max-width: 52%;
    margin-left: auto;
    margin-right: auto;
}
/* Large screens (tablets) */
@media (max-width: 1440px) {
    .aboutTopRight {
        max-width: 50%;
    }
}
/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .aboutTopRight {
        max-width: 100%;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .aboutTopRight {
       max-width: 100%;
    }
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .aboutParagraph {
        text-align: center;
        flex-direction: column;
    }

    .aboutParagraphLineHeight {
        line-height: 3.75rem;
    }

    #play {
        margin-left: 6rem;
        margin-right: 6rem;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    #play {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

.aboutTop {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 9.5vh;
}
/* Large screens (laptops) */
@media (max-width: 1440px) {
    .aboutTop {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4.5rem;
    }
}
/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .aboutTop {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4.5rem;
    }
    
    .aboutTopContainer {
        flex-direction: column;
    }

    .aboutHeroImage {
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .aboutTop {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4.5rem;
    }

    .aboutHeroImage {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

@keyframes fall {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 0;
    }
    /* 50% {
      transform: translateY(40px) rotate(0deg);
      opacity: 0.7;
    } */
    100% {
      /* transform: translateY(0) rotate(0deg); */
      opacity: 1;
    }
}

.tag {
    opacity: 0; /* Make tags invisible initially */
}

.hero {
    gap: 4rem;
    padding-bottom: 0rem;
    position: relative;
    /* min-height: 88vh; */
    max-height: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* justify-content: flex-start; */
}

.heroMainParagraph {
    position: 'relative';
    padding-top: 9.5vh;
    z-index: 0;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.heroMainParagraphContent {
    padding-bottom: 2rem;
}

.heroTagLine {
    justify-content: center !important;
}

/* Medium screens (tablets) */
@media (max-width: 1110px) {
    .hero {
        margin-top: 0rem;
        text-align: center;
    }
    
    .heroMainParagraphContentHeader {
        flex-direction: column;
    }

    .homeh1AfterTags {
        line-height: 4.75rem;
    }

    .heroMainParagraphContent {
        margin-bottom: 0rem;
    }

    .heroMainParagraph {
        padding-bottom: 0rem;
        padding-top: 4rem;
    }
}

/* Small screens (mobile) */
@media (max-width: 768px) {
    .hero {
        margin-top: 0rem;
        text-align: center;
    }

    .homeh1AfterTags {
        line-height: 4.75rem;
    }
    
    .heroMainParagraphContent {
        padding-bottom: 0rem;
        margin-bottom: 6rem;
    }

    .heroMainParagraph {
        padding-bottom: 0rem;
    }
}

.gapHomepage {
    gap: 5rem;
}

.recentWork {
    text-align: center;
    margin-top: 7rem;
    margin-bottom: 1rem;
}

.down-arrow {
    position: absolute;
    top: 610px;
    /* top: calc(82vh - 34px); */
    /* left: calc(0% - 14px); */
    left: 10px;
    width: 0;
    height: 40px;
    border: 2px solid;
    border-radius: 2px;
    animation: jumpInfinite 2.5s infinite;
    animation-delay: 5.5s;
    color: var(--GREY-400);
}

.down-arrow:after {
    content: " ";
    position: absolute;
    top: 16px;
    left: -10px;
    width: 16px;
    height: 16px;
    border-bottom: 4px solid;
    border-right: 4px solid;
    border-radius: 4px;
    transform: rotateZ(45deg);
    color: var(--GREY-400);
}

.down-arrow:hover {
    cursor: url('../assets/cursor-hand.svg'), auto !important;
}

/* Medium screens (tablets) */
@media (max-width: 1110px) {
    .down-arrow {
        display: none;
    }
}

@keyframes jumpInfinite {
    0% {
        margin-top: 0;
    }
    50% {
        margin-top: 20px;
    }
    100% {
        margin-top: 0;
    }
}
