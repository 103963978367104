/* Project page styling */

/* Project page header */
.projectHeaderContainer {
    gap: 3rem;
    text-align: center;
    padding-top: 4.5rem;
}

/* Project page header image */
.projectHeaderImage {
    min-height: 70vh;
    width: 100%;
}
/* Large screens (laptops) */
@media (max-width: 1440px) {
    .projectHeaderImage {
        min-height: 40vh !important;
        width: 100%;
    }
}
/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .projectHeaderImage {
        min-height: 40vh !important;
        width: 100%;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .projectHeaderImage {
        min-height: 35vh !important;
        width: 100%;
    }
}

.projectHeaderImage2Col {
    gap: 4rem;
    padding: 2.25rem 3rem 2.25rem 4.5rem;
}

.projectHeaderImage2ColLeft {
    max-width: 30%;
}

.projectHeaderImage2ColRight {
    max-width: 70%;
}
/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .projectHeaderImage2Col {
        gap: 2rem;
        padding: 2rem;
    }
    
    .projectHeaderImage2ColLeft {
        max-width: 45%;
    }
    
    .projectHeaderImage2ColRight {
        max-width: 80%;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .projectHeaderImage2Col {
        gap: 2rem;
        padding: 2rem;
    }
    
    .projectHeaderImage2ColLeft {
        max-width: 45%;
    }
    
    .projectHeaderImage2ColRight {
        max-width: 100%;
    }
}

/* Text margins */
.projectTextOnlyMargins {
    margin-left: 14rem;
    margin-right: 14rem;
}
/* Large screens (laptops) */
@media (max-width: 1440px) {
    .projectTextOnlyMargins {
        margin-left: 8rem;
        margin-right: 8rem;
    }
}
/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .projectTextOnlyMargins {
        margin-left: 0rem;
        margin-right: 0rem;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .projectTextOnlyMargins {
        margin-left: 0rem;
        margin-right: 0rem;
    }
}

.projectTextImageMargins {
    margin-left: 12rem;
    margin-right: 12rem;
}

.projectTextImageMarginsText {
    margin-left: 2rem;
    margin-right: 2rem;
}

/* Large screens (laptops) */
@media (max-width: 1440px) {
    .projectTextImageMargins {
        margin-left: 6rem;
        margin-right: 6rem;
    }

    .projectTextImageMarginsText {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}
/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .projectTextImageMargins {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .projectTextImageMarginsText {
        margin-left: 0rem;
        margin-right: 0rem;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .projectTextImageMargins {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .projectTextImageMarginsText {
        margin-left: 0rem;
        margin-right: 0rem;
    }
}

.projectContextLeft {
    width: 78%;
    padding-right: 1.5rem;
}

.projectContextRight {
    max-width: 26%;
    gap: 2.5rem;
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .projectContextLeft {
        width: 78%;
        padding-right: 1.5rem;
    }
    
    .projectContextRight {
        max-width: 26%;
        gap: 2.5rem;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .projectContextLeft {
        width: 100%;
        padding-right: 0rem;
    }

    .projectContextRight {
        flex-direction: row;
        max-width: 100%;
    }
    
    .context {
        align-items: flex-start;
    }
}

.projectTextHeader20 {
    width: 22%;
    /* padding-right: 1.5rem; */
}

.projectTextText80 {
    width: 78%;
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .projectTextHeader20 {
        width: 22%;
    }

    .projectTextText80 {
        width: 78%;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .projectTextHeader20 {
        width: 100%;
    }

    .projectTextText80 {
        width: 100%;
    }
}

.gapProjectGrouping {
    gap: 4rem;
}


.gapProjectGroupingImpact {
    gap: 5.75rem;
}

.projectListNumberCircle {
    min-width: 8%;
}

.gapProjectListNumberGrouping {
    gap: 2rem;
}

.gapProjectListNumberItem {
    gap: 2.125rem;
}

.projectCenter {
    text-align: center;
}

.projectCenterSparkles {
    max-width: 10%;
}

.projectRightSparkles {
    max-width: 8%;
    margin-right: 3.5rem;
}

.styledList {
    margin-bottom: 1.5rem;
}

.styledList > li {
    list-style: disc;
    margin-left: 1rem;
    padding-left: 0.75rem;
    margin-top: 1rem;
}

/* Carousel */
#container {
    height:350px; 
    position:relative; 
    overflow:hidden;
}

.photobanner {
    position:absolute; 
    top:0px; 
    left:0px; 
    overflow:hidden; 
    white-space: nowrap;
    /* animation: bannermove 120s linear infinite; */
}

.photobannerSnappea {
    animation: bannermove 100s linear infinite;
}

.photobannerBlueprint {
    animation: bannermove 60s linear infinite;
}

.photobanner img {    
    margin: 0 0.5em;
    height: 350px;
    width: auto;
}

@keyframes bannermove {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-50%, 0);
    }
}

.fun2ColText {
    width: 25%;
}
/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .fun2ColText {
        width: 25%;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .fun2ColText {
        width: 100%;
    }
}
