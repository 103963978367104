/* Text classes and styles follow Figma design system */
/* Global variables used (ex. var(--GLOBAL-FONT), var(--PRIMARY-BLUE-500)) declared in App.css */
* {
    font-family: var(--GLOBAL-FONT);
    font-style: normal;
}

.navInactive {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-decoration: none;
    color: var(--GREY-500);
    /* color: inherit; */
    /* cursor: pointer; */
    cursor: url('../assets/cursor-hand.svg'), auto;
}

.navActive:hover, .navInactive:hover {
    color: var(--BLACK);
}

.navActive {
    color: var(--BLACK);
}

.navInactive:hover {
    color: var(--BLACK);
}

.navActive, .navInactive:hover {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.5rem;
    /* text-decoration: var(--PRIMARY-BLUE-500) line-through; */
    color: var(--BLACK);
    /* text-decoration: var(--PRIMARY-BLUE-500) wavy underline; */
    /* text-decoration: var(--PRIMARY-BLUE-500) underline; */
    text-underline-offset: 4.5px;
    text-decoration-thickness: 2px;
    /* cursor: pointer; */
    cursor: url('../assets/cursor-hand.svg'), auto;
    /* opacity: 100%; */
} 

h1 {
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 3.5rem;
    letter-spacing: -0.04rem;
    color: var(--BLACK);
}

h2 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 3.625rem;
    letter-spacing: -0.055rem;
}

h3 {
    font-size: 2.125rem;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: -0.04rem;
}

h4 {
    font-size: 1.9rem;
    font-weight: 500;
    line-height: 2.875rem;
    letter-spacing: -0.045rem;
}

h5 {
    font-size: 2.05rem;
    font-weight: 500;
    line-height: 2.95rem;
    letter-spacing: -0.02rem;
}

h6 {
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 2.5rem;
    letter-spacing: -0.015rem;
}


/* b1 */
p, li {
    font-size: 1.025rem;
    font-weight: 400;
    line-height: 1.8rem;
    color: var(--GREY-500);
}

.b1Medium {
    font-size: 1.025rem;
    font-weight: 500;
    line-height: 1.8rem;
    color: var(--BLACK);
}

/* b1 link */
a {
    font-size: 1.025rem;
    font-weight: 400;
    line-height: 1.8rem;
    text-decoration-line: none;
    /* color: inherit; */
    color: var(--GREY-500);
    letter-spacing: -0.01rem;
}

a:hover {
    color: var(--BLACK);
    font-weight: 500;
    /* opacity: 80%; */
    /* cursor: pointer; */
    /* text-decoration-line: underline; */
    text-decoration: var(--BLACK);
    /* text-decoration: var(--PRIMARY-BLUE-500) wavy underline; */
    text-underline-offset: 4.5px;
    text-decoration-thickness: 2px;
    cursor: url('../assets/cursor-hand.svg'), auto;
}

.logo {
    text-decoration: none;
    color: inherit;
    /* cursor: pointer; */
    font-size: 1.15rem;
    font-weight: 500;
    line-height: 1.85rem;
    cursor: url('../assets/cursor-hand.svg'), auto;
    letter-spacing: -0.015rem;
}

.b2 {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.75rem;
    color: var(--BLACK);
}

.b3 {
    font-size: 1.35rem;
    font-weight: 400;
    line-height: 2rem;
    color: var(--BLACK);
}

.b3Medium {
    font-size: 1.35rem;
    font-weight: 500;
    line-height: 2rem;
    color: var(--BLACK);
}

/* Tag font */
.tagMini {
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 2.5rem;
    letter-spacing: -0.015rem;
}

.tagSmall {
    font-size: 1.85rem;
    font-weight: 500;
    line-height: 2.875rem;
    letter-spacing: -0.045rem;
}

.tagMedium {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 3.2rem;
    letter-spacing: -0.035rem;
}

.tagLarge {
    font-size: 2.1rem;
    font-weight: 500;
    line-height: 3.625rem;
    letter-spacing: -0.05rem;
}
