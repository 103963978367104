/* GLOBAL STYLES */
:root {
    /* COLOURS */
    --BLACK: #3A3A3A;
    --GREY-600: #505050;
    --GREY-500: #616161;
    --GREY-400: #9C9C9C;
    --GREY-100: #E7E7E9;
    --WHITE: #FDFEFF;

    /* --PRIMARY-BLUE-500: #2459C4; */
    --PRIMARY-BLUE-500: #2459C4;
    --PRIMARY-BLUE-500-OPAQUE: #0048ff0d;
    --PRIMARY-BLUE-300: #D1DEFF;
    --PRIMARY-BLUE-200: #EBEEFF;
    --PRIMARY-BLUE-100: #F1F5FF;
    --PRIMARY-BLUE-50: #F9FAFF;

    --ORANGE-500: #F26855;
    --ORANGE-300: #F9D4CC;
    --ORANGE-200: #FEEFE9;
    --ORANGE-100: #FFF7F1;

    --BLUE-500: #0070F0;
    --BLUE-300: #BDDDFF;
    --BLUE-200: #DDEFF8;
    --BLUE-100: #E3F4FF;

    --PINK-500: #D52A8E;
    --PINK-300: #F6D3F6;
    --PINK-200: #FEE4FE;
    --PINK-100: #FFEBFF;

    --GREEN-500: #038714;
    --GREEN-300: #C4E5C7;
    --GREEN-200: #E0F4E2;
    --GREEN-100: #E5F9EB;

    --RED-500: #F03D40;


    /* FONT FAMILY */
    --GLOBAL-FONT: "Epilogue";
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    cursor: url('./assets/cursor-sparkles.svg'), auto;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    /* background: #FCF9F9; */
    background: var(--WHITE);
    /* background: #F9FAFF; */
    color: var(--BLACK);
}

li {
    list-style: none;
}

/* Default margins */
.marginsPrimary {
    margin-left: 6.25rem;
    padding-left: 1.25rem;
    margin-right: 6.25rem;
    padding-right: 1.25rem;
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .marginsPrimary {
      margin-left: 4rem; /* Adjust the margin for smaller screens */
      padding-left: 1rem; /* Adjust the padding */
      margin-right: 4rem;
      padding-right: 1rem;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .marginsPrimary {
        margin-left: 2rem; /* Smaller margins for mobile */
        padding-left: 0.5rem; /* Adjust padding for mobile */
        margin-right: 2rem;
        padding-right: 0.5rem;
    }
}

.fullPage {
    max-width: 1496px;
    margin-left: auto;
    margin-right: auto;
}

/* Right autolayout align center */
.autoLayoutRightAlignCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 1rem; */
}

/* Right autolayout align left centered */
.autoLayoutRightAlignLeftCenter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* gap: 1rem; */
}

/* Right autolayout align left top */
.autoLayoutRightAlignLeftTop {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    /* gap: 1rem; */
}

/* Right autolayout even gap */
.autoLayoutRightSplit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 1rem; */
}

/* Medium screens (tablets) */
@media (max-width: 1236px) {
    .footerHeader {
        flex-direction: column;
        align-items: center;
        gap: 0.35rem !important;
    }

    /* .footerLinks {
        margin-left: auto;
        margin-right: auto;
    } */

    .footerFootnote {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

.logo:hover {
    text-decoration: none;
}

@media (max-width: 1024px) {
    /* responsiveAutoLayoutDownCenter */
    .autoLayoutRightAlignCenter, 
    .autoLayoutRightAlignLeftCenter, 
    .autoLayoutRightSplit {
        flex-direction: wrap;
        align-items: center;
        /* align-items: flex-start; */
    }

    .autoLayoutRightAlignLeftTop {
        flex-direction: wrap;
        align-items: flex-start;
    }

    .responsiveAutoLayoutRightLeft {
        align-items: flex-start;
        flex-direction: row;
        justify-content: flex-start;
    }

    .responsiveAutoLayoutRightCenter {
        align-items: center;
        flex-direction: row;
        /* margin-left: auto;
        margin-right: auto; */
    }

    .responsiveAutoLayoutRightTop {
        align-items: flex-start !important;
        flex-direction: row;
    }

    .responsiveAutoLayoutDownLeft {
        align-items: flex-start !important;
    }

    .logo {
        margin-left: 14px;
    }
}

/* Small screens (mobile) */
@media (max-width: 768px) {
    .autoLayoutRightAlignCenter, 
    .autoLayoutRightAlignLeftCenter, 
    .autoLayoutRightSplit {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }

    .autoLayoutRightAlignLeftTop {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .responsiveAutoLayoutRightCenter {
        align-items: center;
        flex-direction: row;
    }

    .responsiveAutoLayoutRightLeft {
        align-items: flex-start;
        flex-direction: row;
        justify-content: flex-start;
    }

    .responsiveAutoLayoutRightTop {
        align-items: flex-start !important;
        flex-direction: row;
    }

    .responsiveAutoLayoutDownLeft {
        align-items: flex-start !important;
    }

    .logo {
        margin-left: 14px;
    }

    .footerHeader {
        gap: 0.25rem !important;
    }

    .footerLinks {
        gap: 2rem !important;
    }
}

/* Down autolayout align top */
.autoLayoutDownAlignTop {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* gap: 1rem; */
}

/* Down autolayout align top */
.autoLayoutDownAlignCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* gap: 1rem; */
}

.gapFullPage {
    gap: 8rem;
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .gapFullPage {
        gap: 8rem;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .gapFullPage {
        gap: 6rem;
    }
}

.gaph3b2, .gaph2b1 {
    gap: 1.5rem;
}

.gaph6b1 {
    gap: 1.25rem;
}

.gaph3b1 {
    gap: 1.35rem;
}

.gaph1b1 {
    gap: 2.25rem;
}

.gapb1b1MultiParagraph, .gaph3h3, .gaph1h1 {
    gap: 0.75rem;
}

.gapb1b1SameParagraph {
    gap: 0.25rem;
}

.gapb3b1 {
    gap: 0.65rem;
}

.gapLargeTagInlineText {
    gap: 0.85rem;
}

.gapSmallTagInlineText {
    gap: 0.75rem;
}

.gapImageHeader {
    gap: 2.65rem;
}

.gapImageText {
    gap: 4.5rem;
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .gapImageText {
        gap: 4.5rem;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .gapImageText {
        gap: 2rem;
    }
}

.gapImageImage {
    gap: 4rem;
}

.image {
    width: 100%;
    max-height: 100%;
    border-radius: 1rem;
}

.video {
    width: 96%;
    max-height: 100%;
    border-radius: 1.25rem;
    align-self: center;
    padding: 0rem 0rem;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
}
/* Medium screens (tablets) */
@media (max-width: 1024px) {
    .video {
        width: 96%;
        padding: 0rem 0rem;
    }
}
/* Small screens (mobile) */
@media (max-width: 768px) {
    .video {
        width: 100%;
        padding: 0rem 0rem !important;
        border-radius: 0.5rem !important;
    }
}

.imageMount{
    padding: 1.75rem 1.75rem;
    border-radius: 1.5rem;
    /* background-color: var(--PRIMARY-BLUE-200);
    border: 3px solid var(--PRIMARY-BLUE-300); */
}

/* Fade in animation */
.fadeContentProjects {
    animation: fade-in 1.35s;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 100;
    }
}
